<template>
  <!-- 新闻 -->
  <Layout>
    <div class="main-news">
      <div class="base-width">
        <Search v-ani.fade-up placeholder="请输入关键词搜索" @change="searchChange" />
      </div>
      <div class="c-layout-2">
        <div class="base-width">
          <div class="c-layout-2__lf">
            <!-- 分类 -->
            <Cate :list="cateArr" :curId="String(getData.cat_id)" v-ani.fade-up @change="cateChange" />
          </div>
          <div class="c-layout-2__main" :key="$route.query.id">
            <div class="md-news-1">
              <!-- 广告图 -->
              <Banner class="img" :list="bannerList" height="4.92rem" v-ani.fade-up />
              <!-- 大家都在看 -->
              <HotNews class="rg" v-ani.fade-up :listArr="baseObj.everyone_watching" />
            </div>
            <!-- 列表 -->
            <div class="md-news-2">
              <template v-for="(item, index) in listArr">
                <router-link class="li c-card flex-middle" :key="item.article_id" :class="{ 'li-top': index == 0 }"
                  :to="'/article?id=' + item.article_id" v-ani.fade-up>
                  <ImgRatio v-if="index == 0" :src="item.thumb" :width="220" :height="165" class="img" />
                  <ImgRatio v-else :src="item.thumb" :width="400" :height="300" class="img" />
                  <div class="rg flex-middle">
                    <div class="text">
                      <div class="tit s22 font-medium">{{ item.title }}</div>
                      <div class="data s16 color-6 flex-middle">
                        <div class="data-item flex-middle">
                          <img src="@/assets/images/icon-theme.png">
                          <span>主题:{{ item.cat_name }}</span>
                        </div>
                        <div class="data-item flex-middle">
                          <img src="@/assets/images/icon-read.png">
                          <span>浏览量:{{ item.click }}</span>
                        </div>
                        <div class="data-item flex-middle">
                          <img src="@/assets/images/icon-date.png">
                          <span>{{ item.publish_time | dateFormat('yyyy/MM/dd') }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="btn s16 font-light flex-center">
                      <span>查看详细</span>
                      <span class="icon"></span>
                    </div> -->
                    <BaseButton class="btn font-light" icon='right' styleType="border" hover='green'>
                      查看详细
                    </BaseButton>
                  </div>
                </router-link>
              </template>
            </div>
            <!-- 分页 -->
            <Pagination :page="getData.p" :size="getData.page_num" :total="total" v-ani.fade-up @change="changePage"
              :key="pagKey" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
import { InformationCatListApi, InformationBaseApi, InformationListApi } from "@/request/api/service";
export default {
  created() {
    // 轮播
    IndexBannerApi({ from: 423 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    // 分类
    InformationCatListApi().then(({ status, data }) => {
      if (status == 200) {
        // this.curId = data[0].cat_id;
        this.cateArr = data;
      }
    })
    let cat_id = this.$route.query.cat_id;
    if (cat_id){
      this.getData.cat_id = cat_id;
    }
    // 基础数据
    InformationBaseApi().then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
    this.listFn();
  },
  data() {
    return {
      cateArr: [],
      bannerList: [],
      baseObj: {
        everyone_watching: []
      },
      getData: {
        p: 1,
        page_num: 10,
        keyword: "",
        cat_id: "0"
      },
      listArr: [],
      total: 0,
      pagKey: 0,
      pager: {
        page: 1,
        size: 6,
        total: 600,
      }
    }
  },
  watch: {
    '$route.query': function (val) {
      console.log(val);
    }
  },
  methods: {
    cateChange(e) {
      this.getData.cat_id = e;
      this.getData.p = 1;
      this.listFn();
    },
    searchChange(str) {
      this.getData.p = 1;
      this.getData.keyword = str;
      this.listFn();
    },
    listFn() {
      InformationListApi(this.getData).then(({ status, data }) => {
        if (status == 200) {
          this.listArr = data.list;
          this.total = data.count;
          this.pagKey++;
        }
      })
    },
    // 分页
    changePage(page) {
      this.getData.p = page;
      this.listFn();
      window.scroll({ top: 0, behavior: "smooth", });
    },
  }
};
</script>

<style lang="scss" scoped>
.main-news {
  padding-bottom: 0.6rem;
}

.md-news-1 {
  display: flex;
  justify-content: space-between;

  .img {
    width: 10.02rem;
  }

  .rg {
    width: 3.26rem;
  }
}

.md-news-2 {
  margin-top: .4rem;

  .li {
    padding: .3rem;
    padding-right: 0.5rem;
    margin-bottom: 0.2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      .img {
        ::v-deep .img {
          transform: scale(1.1);
        }
      }

      .btn {
        @include buttonHover($green);
      }
    }

    .img {
      width: 2.2rem;
      margin-right: .5rem;
      border-radius: .04rem;
    }

    .rg {
      flex: 1;
    }

    .text {
      flex: 1;
    }

    .data {
      margin-top: .23rem;

      &-item {
        margin-right: .4rem;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          width: .2rem;
          margin-right: .04rem;
        }
      }
    }

    .btn {
      min-width: 1.2rem;
    }

    &-top {
      .img {
        width: 4rem;
        margin-right: .6rem;
      }

      .rg {
        display: block;
      }

      .data {
        margin-top: .22rem;
      }

      .btn {
        margin-top: .36rem;
      }
    }
  }
}

::v-deep .md-pagination {
  margin-top: .6rem;
}
</style>